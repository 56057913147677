import Axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const axios = Axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  timeout: 60000,
});

axios.interceptors.request.use((request: any) => {
  const token = localStorage.getItem('token');
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }
  request.headers["Content-Type"] = "application/json";
  return request;
});

