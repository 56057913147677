import React from 'react';
import { Flex, Input, InputGroup, InputRightAddon, Select, Text } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { IOrderForm, OrderFormProps } from './types';
import { FormControl } from '@src/components';
import { GoogleSearchInput } from './components';

const OrderForm: React.FC<OrderFormProps> = (props) => {
  const { title, onSubmit, children, defaultValues } = props;

  const { 
    watch,
    control,  
    register, 
    setValue,
    handleSubmit, 
    formState: { errors }, 
  } = useForm<IOrderForm>({ defaultValues });

  return (
    <Flex 
      as='form' 
      onSubmit={handleSubmit(onSubmit)}

      gap='12px'
      direction='column'>

      <Text color='white' fontSize='18px' fontWeight={700}> {title} </Text>

      <Controller 
        control={control}
        name='address'
        rules={{ required: 'This field is required' }}
        render={() => (
          <GoogleSearchInput 
            error={Boolean(errors.address)}
            errorText={errors.address?.message}
            setValueAddress={setValue} />
        )} />
      

      <Controller 
        control={control}
        name='deliveryMethod' 
        rules={{ required: 'This field is required' }}
        render={({ field: { onChange, value } }) => (
          <FormControl 
            isInvalid={Boolean(errors.deliveryMethod)} 
            helperText={errors.deliveryMethod?.message}>
            <Select
              size='lg'
              value={value}
              onChange={onChange}
              placeholder='Delivery Method' 
              focusBorderColor='border.primary'
              color={watch('deliveryMethod') ? 'white' : 'gray.500'}
            >
              <option value='PLATFORM'> PLATFORM </option>
              <option value='TETHER'> TETHER </option>
            </Select>
          </FormControl>
        )} />
      

      <FormControl 
        isInvalid={Boolean(errors.packageContent)} 
        helperText={errors.packageContent?.message}>
        <Input 
          size='lg'
          autoComplete='off'
          placeholder='Package content' 
          focusBorderColor='border.primary'
          {...register('packageContent', { required: 'This field is required' })} />
      </FormControl>

      <FormControl 
        isInvalid={Boolean(errors.clientName)} 
        helperText={errors.clientName?.message}>
        <Input 
          size='lg'
          autoComplete='off'
          placeholder='Client name' 
          focusBorderColor='border.primary'
          {...register('clientName', { required: 'This field is required' })} />
      </FormControl>

      <Flex gap='12px' direction='column'>
        <Text color='white' fontSize='14px' fontWeight={700}> ROUTE </Text>
        <Flex gap='12px' align='center'>
          <FormControl 
            isInvalid={Boolean(errors.altitude)} 
            helperText={errors.altitude?.message}>
            <InputGroup size='lg'>
              <Input 
                size='lg'
                autoComplete='off'
                placeholder='Flight Altitude' 
                focusBorderColor='border.primary'
                {...register('altitude', { required: 'This field is required' })} />
              <InputRightAddon>m</InputRightAddon>
            </InputGroup>
          </FormControl>
          <FormControl 
            isInvalid={Boolean(errors.deliveryAltitude)} 
            helperText={errors.deliveryAltitude?.message}>
            <InputGroup size='lg'>
              <Input 
                size='lg'
                autoComplete='off'
                placeholder='Delivery Altitude' 
                focusBorderColor='border.primary'
                {...register('deliveryAltitude', { required: 'This field is required' })} />
              <InputRightAddon>m</InputRightAddon>
            </InputGroup>
          </FormControl>
        </Flex>
      </Flex>

      <FormControl 
        isInvalid={Boolean(errors.speed)} 
        helperText={errors.speed?.message}>
        <InputGroup size='lg'>
          <Input 
            size='lg'
            autoComplete='off'
            placeholder='Cruise Speed' 
            focusBorderColor='border.primary'
            {...register('speed', { required: 'This field is required' })} />
          <InputRightAddon>m/s</InputRightAddon>
        </InputGroup>
      </FormControl>


      {children}

    </Flex>
  );
};

export default OrderForm;