import React from 'react';
import { ArrowLeftIcon, NotAllowedIcon } from '@chakra-ui/icons';
import { ReactComponent as VideoIcon } from '../../assets/video-camera.svg';
import { Flex, IconButton, Skeleton, Stack, Text } from '@chakra-ui/react';
import { DroneCameraProps } from './types';
import { useDroneCamera } from '../../hooks';
import { WebSocketContext } from '@src/context/ws-context';
import { useSendCameraPitchMutation } from '@src/features/orders/redux/api';
import { useSelector } from 'react-redux';
import { selectorSelectedGroupOrder } from '@src/features/orders/redux/extra-store/selectors';

const DroneCamera: React.FC<DroneCameraProps> = (props) => {
  const { droneSerialNumber } = props;

  const [sendPitch, { isLoading: isPitchLoading }] = useSendCameraPitchMutation();
  const selected = useSelector(selectorSelectedGroupOrder);

  const [cameraEuler, setCameraEuler] = React.useState<any>({});
  const { subscribe, unsubscribe } = React.useContext(WebSocketContext);
  const { isOpen, isLoading, onStartVideo, onStopVideo, videoRef } = useDroneCamera(droneSerialNumber || '');


  const onSliderControl = (pitch: number) => {
    if (!selected?.missionId) return;

    sendPitch({
      pitch: pitch,
      command: 'GIMBAL_CONTROL',
      missionId: selected.missionId,
    });
  };

  React.useEffect(() => {
    subscribe('camera_attitude_euler', (event: { value: {} }) => {
      setCameraEuler(event.value);
    });
    return () => unsubscribe('camera_attitude_euler');
  }, [subscribe, unsubscribe]);

  React.useEffect(() => {
    return () => onStopVideo();
  }, []);


  const isDisabled = Object.keys(cameraEuler).length < 1;

  if (isOpen) {
    return (
      <Flex 
        p='12px'
        w='100%' 
        direction='column'
        position='relative'>

        <Flex >
          {isLoading &&
            <Skeleton 
              position='absolute'
              top={0}
              left={0}
              right={0}
              width='100%' 
              height='190px'
              startColor='blackAlpha.400'
              endColor='blackAlpha.700' /> 
          }
          <video
            autoPlay 
            playsInline
            width='100%' 
            height='190px'
            ref={videoRef}
          />

          <Stack align='center' justifyContent='center'>
            <IconButton 
              size='sm' 
              aria-label='icon-button-up' 
              isLoading={isPitchLoading || isDisabled}
              onClick={() => onSliderControl(100)}
              icon={<ArrowLeftIcon transform='rotate(90deg)' />} />
            
            <Text color='white' fontWeight={700}> 
              {isPitchLoading || isDisabled ? '...' : cameraEuler.pitch_deg?.toFixed() ?? 100}
            </Text>

            <IconButton 
              size='sm' 
              isLoading={isPitchLoading || isDisabled}
              aria-label='icon-button-down' 
              onClick={() => onSliderControl(0)}
              icon={<ArrowLeftIcon transform='rotate(-90deg)' />} />
          </Stack>
        </Flex>
        
        <IconButton 
          mt='12px'
          mx='auto'
          width='50px'
          height='50px' 
          colorScheme='red'
          aria-label='Stop Video'
          isLoading={isLoading}
          onClick={onStopVideo} 
          icon={<NotAllowedIcon w='24px' height='24px' />}
        />
      </Flex>
    );
  }
  return (
    <IconButton
      mx='auto'
      width='50px'
      height='50px'
      variant='brand'
      aria-label='Start Video'
      isLoading={isLoading}
      onClick={onStartVideo}
      icon={<VideoIcon />}
    />
  );
};

export default DroneCamera;
